var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.Disease && Object.keys(_vm.Disease).length)?_c('main',[(_vm.Disease.banners && _vm.Disease.banners.length)?_c('PagesSlider',{attrs:{"title":_vm.Disease.title,"banners":_vm.Disease.banners,"color":_vm.Disease.color}}):_vm._e(),_c('Breadcrumbs',{attrs:{"color":_vm.Disease.color,"pages":[
        {
          name: 'Терапевтические области',
          link: {
            name: 'Nosology',
          },
        },
        {
          name: _vm.Disease.parent.title,
          link: {
            name: 'NosologyDetail',
            params: { slug: _vm.Disease.parent.slug },
          },
        },
        { name: _vm.Disease.title } ]}}),_c('section',{staticClass:"therapy-areas failure-page__section"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"failure-page__row"},[_c('div',{staticClass:"failure-page__categories"},[(_vm.filteredLinks && _vm.filteredLinks.length)?_c('div',{staticClass:"materials"},_vm._l((_vm.filteredLinks),function(material){return _c('FileBlock',{key:material.id,attrs:{"material":material},on:{"linkClick":_vm.linkClick}})}),1):_vm._e(),(_vm.Disease.name)?_c('div',{staticClass:"failure-page__title",domProps:{"innerHTML":_vm._s(_vm.Disease.name)}}):_vm._e(),_c('div',{ref:"description",staticClass:"failure-page__description",domProps:{"innerHTML":_vm._s(_vm.Disease.description)}}),_c('div',{staticClass:"failure-page__categories-row"},[_vm._l((_vm.filteredContent),function(info,ind){return _c('InfoDrop',{key:ind,attrs:{"color":_vm.Disease.color,"item":info},on:{"openImage":_vm.openImage}})}),_c('CoolLightBox',{attrs:{"items":_vm.items,"index":_vm.index},on:{"close":function($event){return _vm.onClose()}}})],2)]),(_vm.asideItems && _vm.asideItems.length && _vm.filteredContent.length)?_c('RightAside',{staticClass:"failure-page__aside",attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"libClick":_vm.libClick}}):_vm._e()],1),(
            _vm.Disease.library && _vm.Disease.library.length && _vm.sliderItems.length
          )?_c('LibrarySlider',{staticClass:"mt-16 mb-16 pb-0 pt-0 mt-0",class:{ 'mt-16': _vm.filteredContent.length },staticStyle:{"background-color":"#fff"},attrs:{"nosology":{ title: _vm.Disease.title },"items":_vm.sliderItems,"color":_vm.Disease.color}}):_vm._e(),(_vm.asideItems && _vm.asideItems.length && !_vm.filteredContent.length)?_c('RightAside',{staticClass:"failure-page__aside medication-aside_flexible",attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"libClick":_vm.libClick}}):_vm._e()],1)])],1):(_vm.Disease === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):(_vm.Disease === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }